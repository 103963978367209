import { Fragment } from 'react';
import Slide1Container from './NavbarComponents/Slide1Container';
import Slide2Container from './NavbarComponents/Slide2Container';
import Slide3Container from './NavbarComponents/Slide3Container';

const Navbar = ({nextEvent, t, i18n}) => {

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  return (
    <Fragment>
      <nav id='mainNavBar' className="navbar fixed-top navbar-expand-lg navbar-dark poppins">
        <div className="container">
          <a className="navbar-brand chaknuul" style={{ fontSize: 26 }} href="#">
            <img src="./img/logo-sin-letras.png" width="50" height="40" alt="" />
            &nbsp;
            Chaknúul
          </a>
          <div>
            &nbsp;
            &nbsp;
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="mx-auto"></div>
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link text-white" href="#actividades">{t("_Actividades")}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="#faq">{t("_FAQs")}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="#contact">{t("_Contacto")}</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="banner-image w-100 vh-100 d-flex justify-content-center align-items-center">
          

        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item divcarusel active" style={{ background: `url("./img/slide3.jpg")` }}>
                  <div className="gradient"></div>
                  <Slide1Container
                    t={t}
                  ></Slide1Container>
                </div>
                <div className="carousel-item divcarusel" style={{ background: `url("./img/slide1.jpg")` }}>
                  <div className="gradient"></div>
                  <Slide2Container
                    t={t}
                  ></Slide2Container>
                </div>
                { nextEvent &&
                  <div className="carousel-item divcarusel" style={{ background: `url("./img/proximos/${nextEvent.subTipo}.png")`,opacity: .8 }}>
                    <div className="gradient"></div>
                    <Slide3Container 
                    t={t}
                    nextEvent={nextEvent}
                  ></Slide3Container>
                  </div>
                }
              </div>
              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
      </div>
    </Fragment>
  );
}
export default Navbar;