import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EventContainer from "../Home/HomeComponents/EventContainer";
import { useDispatch, useSelector } from "react-redux";
import ContactContainer from "../Home/HomeComponents/ContactContainer";
import { requestHomeModuleInfo } from "../../ReduxSaga/Actions/Home";
const AllEventsContainer = ({}) =>
{
    const dispatch = useDispatch();

    const [msjEventos, setMsjEventos] = useState("Todos los eventos");
    const [eventos, setEventos] = useState([]);
    const [eventosF, setEventosF] = useState([]);

    const appReducers = {};
    appReducers.user = useSelector((state) => state.user);
    appReducers.home = useSelector((state) => state.home);

    useEffect(() => {
      dispatch(requestHomeModuleInfo());
    },[])
  
    useEffect(() => {
      if(appReducers.home.homeModuleEs?.eventos)
      {
        setEventos(appReducers.home.homeModuleEs?.eventos.filter(f => f.activo));
      }
    },[appReducers.home.homeModuleEs?.eventos])

    useEffect(() => {
        if(eventos.length > 0)
        {
            setEventosF(eventos)
        }
        else
        {
        }
    }, [eventos])

    const activarCategoria = (cat) => 
    {
        document.getElementById("cat2").className = "";
        document.getElementById("cat3").className = "";
        document.getElementById("cat4").className = "";

        switch(cat){
            case "cat1":
                setMsjEventos("Todos los eventos");
                setEventosF(eventos);
                break;
            case "cat2":
                setMsjEventos("Proximas Clases, Cursos y Talleres");
                setEventosF(eventos.filter((f) => f.tipo=="clase"));
                document.getElementById(cat).className = "active-event";
                break;
            case "cat3":
                setMsjEventos("Proximos Viajes y Excursiones");
                setEventosF(eventos.filter((f) => f.tipo=="viaje"));
                document.getElementById(cat).className = "active-event";
                break;
            case "cat4":
                setMsjEventos("Proximas Convivencias");
                setEventosF(eventos.filter((f) => f.tipo=="especial"));
                document.getElementById(cat).className = "active-event";
                break;
        }
    }

    const handleOpenWhatsApp = (msg)=>{
        if(msg != "")
        {
        window.open('https://api.whatsapp.com/send?phone=5611035720&text=Hola quisiera mas informacion del evento ' + msg, '_blank')
        }
        window.open('https://api.whatsapp.com/send?phone=5611035720&text=Hola, quisiera mas informacion sobre sus actividades', '_blank')
    }


    return (
        <div>
            <nav id='mainNavBar' className="mainNavBarEventos poppins">
                <div className="container">
                <a className="navbar-brand chaknuul" style={{ fontSize: 26, color: 'white' }} href="#">
                    <img src="./img/logo-sin-letras.png" width="50" height="40" alt="" />
                    &nbsp;
                    Chaknúul
                </a>
                <div>
                </div>
                </div>
            </nav>
            <center>
            <br></br>
                <Link className="px-0" to="/">{"Volver al sitio"}</Link>

                <div className="row-activities mb-2 mt-2">
                    <div className="col-4">
                        <div id="cat2" onClick={() => { activarCategoria("cat2") }} className="">
                            <img src="./img/activities/classes.png" className="imgRedonda p-1"></img>
                        </div>
                    </div>
                    <div className="col-4">
                        <div id="cat3" onClick={() => { activarCategoria("cat3") }} className="">
                            <img src="./img/activities/travels.png" className="imgRedonda p-1"></img>
                        </div>
                    </div>
                    <div className="col-4">
                        <div id="cat4" onClick={() => { activarCategoria("cat4") }} className="">
                            <img src="./img/activities/events.png" className="imgRedonda p-1"></img>
                        </div>
                    </div>
                </div>
                <div className="row-mobile">
                    <div className="col-12">
                            {msjEventos}
                            <br></br>
                            <a style={{ fontSize: 14, color: "blue" }} onClick={() => { activarCategoria("cat1") }}>Ver todos</a>
                            <br></br>
                            <div className="row">
                            {
                                eventosF && eventosF.map((evento, index) => (
                                    <div key={index + "-" + evento.id} className="col-6 px-2">
                                        <EventContainer handleOpenWhatsApp={handleOpenWhatsApp} key={index} evento={evento}></EventContainer>
                                    </div>
                                ))
                            }
                            </div>
                    </div>
                </div>
            </center>
            <div id="contact">
                <br></br>
                <br></br>
                <br></br>
            </div>
            <ContactContainer whatsapp={handleOpenWhatsApp}></ContactContainer>
            <a onClick={() => handleOpenWhatsApp()} className="float">
                <i className="fa fa-whatsapp my-float fa-xg"></i>
            </a>
        </div>
    );
}

export default AllEventsContainer;