import { Fragment } from "react";
import { Link } from "react-router-dom";


const ContactContainer = ({whatsapp, t}) => 
{
    return (
        <div className="contacto-class">
            <center>
                <br></br>
                <div className="row slide-pc-version">
                    <div className="col-2">
                    </div>
                    <div className="col-2">
                        <br></br>
                        <br></br>
                        <a className="navbar-brand chaknuul" href="#" style={{ fontSize: 40 }}>
                            <img src="./img/logo-sin-letras.png" width="100" height="70" alt=""/>
                            <br></br>
                            Chaknúul
                        </a>
                    </div>
                    <div className="col-3 text-left">
                        <h5>{t("_RedesSociales")}</h5>
                        <a href="#contact" onClick={() => whatsapp()} style={{ textDecoration: 0 }}>
                            <img className="redes-class" src="./img/redes/whatsapp.png"></img>
                            &nbsp;{t("_WhatsAppMsg")}
                        </a>
                        <br></br>
                        <a href="https://www.instagram.com/chaknuul_mx" style={{ textDecoration: 0 }} target="_blank">
                            <img className="redes-class" src="./img/redes/instagram.png"></img>
                            &nbsp;{t("_InstagramMsg")}
                        </a>
                        <br></br>
                        <a href="https://www.tiktok.com/@chaknuul.casa.nudista" style={{ textDecoration: 0 }} target="_blank">
                            <img className="redes-class" src="./img/redes/tiktok.png"></img>
                            &nbsp;{t("_TikTokMsg")}
                        </a>
                        <br></br>
                        <a href="https://x.com/CHAKNUUL" target="_blank" style={{ textDecoration: 0 }}>
                            <img className="redes-class" src="./img/redes/x.png"></img>
                            &nbsp;{t("_XMsg")}
                        </a>
                    </div>
                    <div className="col-3 text-left">
                        <h5>{t("_Admin")}</h5>
                        <Link to="/login"  style={{ textDecoration: 0 }}>
                            <img className="redes-class" src="./img/redes/admin.png"></img>
                            &nbsp;{t("_PanelAdmin")}
                        </Link>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row slide-mobile-version">
                    <div className="col-1">
                    </div>
                    <div className="col-6 text-left">
                        <h5>{t("_RedesSociales")}</h5>
                        <a href="#contact" onClick={() => whatsapp()} style={{ textDecoration: 0, fontSize: 10 }}>
                            <img className="redes-class" src="./img/redes/whatsapp.png"></img>
                            &nbsp;{t("_WhatsAppMsg")}
                        </a>
                        <br></br>
                        <a href="https://www.instagram.com/chaknuul_mx" style={{ textDecoration: 0, fontSize: 10 }} target="_blank">
                            <img className="redes-class" src="./img/redes/instagram.png"></img>
                            &nbsp;{t("_InstagramMsg")}
                        </a>
                        <br></br>
                        <a href="https://www.tiktok.com/@chaknuul.casa.nudista" style={{ textDecoration: 0, fontSize: 10 }} target="_blank">
                            <img className="redes-class" src="./img/redes/tiktok.png"></img>
                            &nbsp;{t("_TikTokMsg")}
                        </a>
                        <br></br>
                        <a href="https://x.com/CHAKNUUL" target="_blank" style={{ textDecoration: 0, fontSize: 10 }}>
                            <img className="redes-class" src="./img/redes/x.png"></img>
                            &nbsp;{t("_TikTokMsg")}
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <h5>{t("_Admin")}</h5>
                        <Link to="/login"  style={{ textDecoration: 0, fontSize: 10 }}>
                            <img className="redes-class" src="./img/redes/admin.png"></img>
                            &nbsp;{t("_PanelAdmin")}
                        </Link>
                    </div>
                    <div className="col-1">
                    </div>
                </div>
            </center>
        </div>
    );
}

export default ContactContainer;