import { 
    CLEAR_CLONE_ESTATUS_REDUCER,
    CLEAR_UPDATE_ESTATUS_REDUCER,
    CLEAR_UPDATE_SUBTIPO_REDUCER,
    CLEAR_UPDATE_TIPO_REDUCER,
    REQUEST_ADD_OR_UDPATE_EVENT, 
    REQUEST_ALL_EVENTS, 
    REQUEST_CLONE_EVENT, 
    REQUEST_FORM_CATALOG, 
    REQUEST_HOME_MODULE_INFO, 
    REQUEST_LOGIN_USER, 
    REQUEST_UPDATE_EVENT_ESTATUS,
    REQUEST_UPDATE_SUBTIPO,
    REQUEST_UPDATE_TIPO
} from './../../Constants'

export const requestHomeModuleInfo = () => {
    return {
        type: REQUEST_HOME_MODULE_INFO,
        payload: {}
    }
}
export const requestAllEvents = () => {
    return {
        type: REQUEST_ALL_EVENTS,
        payload: {}
    }
}
export const requestFormCatalogs = () => {
    return {
        type: REQUEST_FORM_CATALOG,
        payload: {}
    }
}
export const requestAddOrUpdateEvento = (event) => {
    return {
        type: REQUEST_ADD_OR_UDPATE_EVENT,
        payload: {
            event
        }
    }
}
export const requestChangeEventStatus = (id, status) => {
    return {
        type: REQUEST_UPDATE_EVENT_ESTATUS,
        payload: {
            id, status
        }
    }
}
export const requestClearUpdateEstatusReducer = () => {
    return {
        type: CLEAR_UPDATE_ESTATUS_REDUCER,
        payload: {}
    }
}
export const requestCloneEvent = (id, fecha) => {
    return {
        type: REQUEST_CLONE_EVENT,
        payload: {
            id, fecha
        }
    }
}
export const requestClearCloneEstatusReducer = () => {
    return {
        type: CLEAR_CLONE_ESTATUS_REDUCER,
        payload: {}
    }
}
export const requestLoginUser = (userModel) => {
    return {
        type: REQUEST_LOGIN_USER,
        payload: {
            userModel
        }
    }
}
export const requestUpdateTipo = (tipoModel) => {
    return {
        type: REQUEST_UPDATE_TIPO,
        payload: {
            tipoModel
        }
    }
}
export const requestUpdateSubTipo = (subTipoModel) => {
    return {
        type: REQUEST_UPDATE_SUBTIPO,
        payload: {
            subTipoModel
        }
    }
}
export const requestClearTipoReducer = () => {
    return {
        type: CLEAR_UPDATE_TIPO_REDUCER,
        payload: {}
    }
}
export const requestClearSubTipoReducer = () => {
    return {
        type: CLEAR_UPDATE_SUBTIPO_REDUCER,
        payload: {}
    }
}