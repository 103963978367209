import { fork } from 'redux-saga/effects'
import { 
   watchRequestHomeModuleInfo, 
   watchRequestFormcatalogs, 
   watchRequestAddOrUpdateEventData, 
   watchRequestAllEvents, 
   watchRequestChangeStatusEvent, 
   watchRequestCloneEvent, 
   watchRequestLoginUser,
   watchRequestUpdateTipo,
   watchRequestUpdateSubTipo
} from './Home'


export default function* rootSaga() {
   yield fork(watchRequestHomeModuleInfo) 
   yield fork(watchRequestAllEvents) 
   yield fork(watchRequestFormcatalogs)
   yield fork(watchRequestAddOrUpdateEventData)
   yield fork(watchRequestChangeStatusEvent)
   yield fork(watchRequestCloneEvent)
   yield fork(watchRequestLoginUser)
   yield fork(watchRequestUpdateTipo)
   yield fork(watchRequestUpdateSubTipo)
}