import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { requestChangeEventStatus } from "../../../ReduxSaga/Actions/Home";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const ListaEventosContainer = ({
    eventosList,
    handleNewEvent,
    lastSelected, 
    setLastSelected,
    handleEditEvent,
    handleDuplicateEvent,
    showDuplicateModal, 
    setShowDuplicateModal,
    eventSelected,
    setEventSelected,
    clonedOk,
    setClonedOk,
    Swal
}) => {
    const dispatch = useDispatch();

    const [alter, setAlter] = useState("");
    const [selected, setSelected] = useState(false);
    const [fechaClon, setFechaClon] = useState(new Date())
    const [msgValidation, setMsgValidation] = useState("");
    const [hiddenDuplicateModal, setHiddenDuplicateModal] = useState(false)
    const [regPerPage, setRegPerPage] = useState(15)
    const [page, setPage] = useState(1)
    const [regIni, setRegIni] = useState(0)

    const getFechaFormateada = (fecha) =>
    {
        var date = new Date(fecha);
        var mes = date.toLocaleString('default', { month: 'long' })
        if(date.getFullYear() > 2099)
            return "SIN FECHA";
        else
            return  date.getFullYear() + "-" + ("0" + (date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
    }
    const getFechaFormateadaService = (fecha) =>
    {
        var date = new Date(fecha);
        return  date.getFullYear() + "-" + ("0" + (date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + "T" + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
    }

    const clickCheckInput = (id) => 
    {
        if(lastSelected != id)
        {
            if(lastSelected != 0){
                document.getElementById("check_"+lastSelected).checked = false;
            }
            setLastSelected(id);
            var sel = eventosList.filter(f => f.id == id)[0];
            setEventSelected(sel);
            setFechaClon(new Date());
        }
        var check = document.getElementById("check_"+id);
        if(check.checked)
        {
            setSelected(true);
        }
        else
        {
            setSelected(false);
        }
    }

    const InactiveEvent = (id) => 
    {
        dispatch(requestChangeEventStatus(id, false));
    }
    const ActiveEvent = (id) => 
    {
        dispatch(requestChangeEventStatus(id, true));
    }
    const handleValidateDuplicateEvent = () => 
    {
        if(fechaClon < new Date())
        {
            setMsgValidation("La fecha del evento no puede ser menor a la fecha actual")
        }
        handleDuplicateEvent(eventSelected.id, getFechaFormateadaService(new Date(fechaClon)));
    }


    useEffect(() => {
        setSelected(false)
    },[])
    
    useEffect(() => {
        setMsgValidation("")
    },[fechaClon])

    useEffect(() => {
        if(clonedOk)
        {
            setClonedOk(false);
            let timerInterval;
            Swal.fire({
                title: "El evento se duplico correctamente!",
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: () => {},
                willClose: () => {
                    clearInterval(timerInterval);
                }
                }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                }
            });
        }
    },[clonedOk])

    useEffect(() => {
        
    },[])

    return (
        <div>
            <table className="tabla-htas">
                <tbody>
                    <tr>
                        <td scope="col" width="100%">
                            <button disabled={!selected} className="btn btn-primary" data-toggle="modal" data-target="#duplicateModal">
                            <i className="fa-regular fa-copy"></i>&nbsp;
                            Duplicar
                            </button>
                            &nbsp;
                            <button onClick={() => handleEditEvent()} disabled={!selected} className="btn btn-primary">
                                <i className="fa-regular fa-pen-to-square"></i>&nbsp;
                                Editar
                            </button>
                            &nbsp;
                            <button onClick={() => handleNewEvent()} className="btn btn-primary">
                                <i className="fa-solid fa-plus"></i>&nbsp;
                                Nuevo
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="table-striped tablaEventos">
            <thead>
                <tr>
                    <th scope="col" width="3%"></th>
                    <th scope="col" width="10%">Fecha Evento</th>
                    <th scope="col" width="10%">Tipo</th>
                    <th scope="col" width="10%">Sub Tipo</th>
                    <th scope="col" width="50%">Titulo</th>
                    <th scope="col" width="10%"><center>Estado</center></th>
                </tr>
            </thead>
            <tbody>
            {
                eventosList.slice(regIni, (regIni+regPerPage)).map((e, index) => {
                    return (
                        <tr key={e.id} className="hover-fila" id={e.id}>
                            <td><center><input id={"check_" + e.id} onClick={() => clickCheckInput(e.id)} type="checkbox"></input></center></td>
                            <td>{getFechaFormateada(e.fecha)}</td>
                            <td>{e.tipo}</td>
                            <td>{e.subTipo}</td>
                            <td>{e.titulo}</td>
                            <td>
                                <center>
                                {
                                    e.activo ? 
                                    <button className="btn btn-outline-success mybtn" onClick={()=>InactiveEvent(e.id)}>
                                        <i className="fa-solid fa-power-off"></i>&nbsp;
                                        Activo
                                    </button>
                                    :
                                    <button className="btn btn-outline-secondary mybtn" onClick={()=>ActiveEvent(e.id)}>
                                        <i className="fa-solid fa-power-off"></i>&nbsp;
                                        Inactivo
                                        </button>
                                }
                                </center>
                            </td>
                        </tr>
                    
                )})
            }
            </tbody>
            </table>
            {
                !hiddenDuplicateModal &&
                (
                    <div className="modal fade" id="duplicateModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">{eventSelected.titulo}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body text-justify">
                                    El evento se clonara con los mismos detalles con la fecha que seleccione
                                    <hr></hr>
                                    <div className="row">
                                        <div className="col-6">
                                            <DatePicker
                                                id="dp-fecha"
                                                className="form-control"
                                                selected={fechaClon}
                                                onChange={(date) => {setFechaClon(date)}}
                                                dateFormat="yyyy-MM-dd"
                                                minDate={new Date()}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <DatePicker
                                                id="dp-hora"
                                                className="form-control"
                                                selected={fechaClon}
                                                onChange={(date) => {setFechaClon(date)}}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="hh:mm aa"
                                                minDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <br></br>
                                    {
                                        msgValidation != "" ?
                                            <div id="div-validation" className="alert alert-danger" role="alert">
                                                {msgValidation}
                                            </div>
                                            :
                                            <Fragment></Fragment>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                    <a onClick={() => handleValidateDuplicateEvent()}><button type="button" data-dismiss="modal" className="btn btn-primary">Duplicar Evento</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}
export default ListaEventosContainer;