

window.addEventListener('scroll', function () {
    var nav = document.getElementById('mainNavBar');
    var menu = document.getElementById('navbarNav');
    if(nav != undefined && menu != undefined)
    if (window.pageYOffset > 100) {
        if(!nav.className.includes("bg-darky p-1"))
        {
            nav.className = nav.className + " bg-darky p-1";
        }
        menu.className = menu.className.replace(" show", "");
    } else {
        if(nav.className.includes("bg-darky p-1"))
        {
            nav.className = nav.className.replace(" bg-darky p-1", "");
        }
    }
});