import { Fragment } from "react";


const Slide3Container = ({nextEvent, t}) => {

    
    const handleOpenWhatsApp = (msg)=>{
        window.open('https://api.whatsapp.com/send?phone=5611035720&text=Hola, me interesa su evento ' + msg, '_blank')
    }

    const getFechaFormateada = (fecha) =>
    {
        var date = new Date(fecha);
        var mes = date.toLocaleString('default', { month: 'long' })
        if(date.getFullYear() > 2099)
            return "PROXIMAMENTE";
        else
            return ("0" + date.getDate()).slice(-2) + " " + mes.charAt(0).toUpperCase() + mes.slice(1) + " " + date.getFullYear();
    }
    const getHoraFormateada = (fecha) =>
    {
        var date = new Date(fecha);
        if(date.getFullYear() > 2099)
            return "";
        else
            return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + " Hrs";
    }

    return (
        <div className="carousel-texto-3">
            <h5 className="c-one">{t("_NoTePierdasProximoEvento")}</h5>
            <br></br>
            <p className='poppins' style={{ textAlign: 'center' }}>
            {nextEvent.titulo}
            <br></br>
            </p>
            <p className='poppins fpe' style={{ textAlign: 'justify' }}>
            {nextEvent.informacion}
            <br />
            <br />
            {nextEvent.objetivo}
            <br />
            </p>
            <p className='poppins fpe' style={{ textAlign: 'center' }}>
            {nextEvent.llamado}
            <br></br>
            <br></br>
            {getFechaFormateada(nextEvent.fecha) + " " + getHoraFormateada(nextEvent.fecha)}
            <br />
            <br />
            <a href="" onClick={() => handleOpenWhatsApp("" + nextEvent.titulo + " del " + getFechaFormateada(nextEvent.fecha))}>
                <button type="button" className="btn btn-success"><i className="fa-brands fa-whatsapp fa-lg"></i>&nbsp;&nbsp;{t("_MeInteresa")}</button>
            </a>
            </p>
        </div>
    );
}
export default Slide3Container;