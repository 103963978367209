import { call, put, takeLatest } from 'redux-saga/effects'
import {
    REQUEST_HOME_MODULE_INFO, 
    RESPONSE_HOME_MODULE_INFO, 
    DATA_FETCH_FAILED,
    RESPONSE_FORM_CATALOG,
    REQUEST_FORM_CATALOG,
    RESPONSE_ADD_OR_UDPATE_EVENT,
    REQUEST_ADD_OR_UDPATE_EVENT,
    RESPONSE_ALL_EVENTS,
    REQUEST_ALL_EVENTS,
    REQUEST_UPDATE_EVENT_ESTATUS,
    RESPONSE_UPDATE_EVENT_ESTATUS,
    RESPONSE_CLONE_EVENT,
    REQUEST_CLONE_EVENT,
    RESPONSE_LOGIN_USER,
    REQUEST_LOGIN_USER,
    RESPONSE_UPDATE_TIPO,
    RESPONSE_UPDATE_SUBTIPO,
    REQUEST_UPDATE_TIPO,
    REQUEST_UPDATE_SUBTIPO

} from '../../Constants'
import HomeModule from '../../../../Libraries/Home'

function* requestHomeModuleInfo(){
    try {
        const response = yield call(HomeModule.GetHomeModule);
        yield put({type: RESPONSE_HOME_MODULE_INFO, payload: { response:response }});

    } catch (error) {
        console.error("load home module error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestAllEvents(){
    try {
        const response = yield call(HomeModule.GetAllEvents);
        yield put({type: RESPONSE_ALL_EVENTS, payload: { response:response }});

    } catch (error) {
        console.error("load home module error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestFormCatalogs(){
    try {
        const response = yield call(HomeModule.GetCatalogos);
        yield put({type: RESPONSE_FORM_CATALOG, payload: { response:response }});

    } catch (error) {
        console.error("load home module error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestAddOrUpdateEventData(data){
    try {
        const response = yield call(HomeModule.AddOrUpdateEvents, data.payload.event);
        yield put({type: RESPONSE_ADD_OR_UDPATE_EVENT, payload: { response:response }});

    } catch (error) {
        console.error("update event data error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestChangeStatusEvent(data){
    try {
        const response = yield call(HomeModule.ChangeStatusEvent, data.payload.id, data.payload.status);
        yield put({type: RESPONSE_UPDATE_EVENT_ESTATUS, payload: { response:response }});

    } catch (error) {
        console.error("update event data error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestCloneEvent(data){
    try {
        const response = yield call(HomeModule.CloneEvent, data.payload.id, data.payload.fecha);
        yield put({type: RESPONSE_CLONE_EVENT, payload: { response:response }});

    } catch (error) {
        console.error("update event data error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestLoginUser(data){
    try {
        const response = yield call(HomeModule.RequestLoginUser, data.payload.userModel);
        yield put({type: RESPONSE_LOGIN_USER, payload: { response:response }});

    } catch (error) {
        console.error("update event data error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestUpdateTipo(data){
    try {
        const response = yield call(HomeModule.RequestUpdateTipo, data.payload.tipoModel);
        yield put({type: RESPONSE_UPDATE_TIPO, payload: { response:response }});

    } catch (error) {
        console.error("update event data error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestUpdateSubTipo(data){
    try {
        const response = yield call(HomeModule.RequestUpdateSubTipo, data.payload.subTipoModel);
        yield put({type: RESPONSE_UPDATE_SUBTIPO, payload: { response:response }});

    } catch (error) {
        console.error("update event data error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

export function* watchRequestHomeModuleInfo() {
    yield takeLatest(REQUEST_HOME_MODULE_INFO, requestHomeModuleInfo);
}
export function* watchRequestAllEvents() {
    yield takeLatest(REQUEST_ALL_EVENTS, requestAllEvents);
}
export function* watchRequestFormcatalogs(){
    yield takeLatest(REQUEST_FORM_CATALOG, requestFormCatalogs);
}
export function* watchRequestAddOrUpdateEventData(){
    yield takeLatest(REQUEST_ADD_OR_UDPATE_EVENT, requestAddOrUpdateEventData);
}
export function* watchRequestChangeStatusEvent(){
    yield takeLatest(REQUEST_UPDATE_EVENT_ESTATUS, requestChangeStatusEvent);
}
export function* watchRequestCloneEvent(){
    yield takeLatest(REQUEST_CLONE_EVENT, requestCloneEvent);
}
export function* watchRequestLoginUser(){
    yield takeLatest(REQUEST_LOGIN_USER, requestLoginUser);
}
export function* watchRequestUpdateTipo(){
    yield takeLatest(REQUEST_UPDATE_TIPO, requestUpdateTipo);
}
export function* watchRequestUpdateSubTipo(){
    yield takeLatest(REQUEST_UPDATE_SUBTIPO, requestUpdateSubTipo);
}