import { Fragment } from "react";


const Slide1Container = ({t}) => {
    return (
        <div className="carousel-texto">
            <img src='./img/logo-sin-letras.png' className='imgMain'></img>
            <br />
            <h2 className='chaknuul' style={{ fontSize: 35 }}>Chaknúul</h2>
            <h2 className='poppins' style={{ fontSize: 15 }}>{t("_CasaNudista")}</h2>
            <p className='poppins' style={{ textAlign: 'justify' }}>
            <br />
            {t("_Presentacion")}
            <br />
            <br />
            <br />
            <a href="#nosotros" className="links-chaknuul">{t("_ConoceMas")}</a>
            </p>
        </div>
    );
}
export default Slide1Container;