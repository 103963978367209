export const ENVIROMENT_URL = ''
      
//CATCHING ERRORS FETCHING
export const DATA_FETCH_FAILED = 'DATA_FETCH_FAILED'

//USERS
export const REQUEST_LOGIN_USER = 'REQUEST_LOGIN_USER'
export const RESPONSE_LOGIN_USER = 'RESPONSE_LOGIN_USER'

//HOME
export const REQUEST_HOME_MODULE_INFO = 'REQUEST_HOME_MODULE_INFO'
export const RESPONSE_HOME_MODULE_INFO = 'RESPONSE_HOME_MODULE_INFO'

//HOME
export const REQUEST_FORM_CATALOG = 'REQUEST_FORM_CATALOG'
export const RESPONSE_FORM_CATALOG = 'RESPONSE_FORM_CATALOG'

//EVENT
export const REQUEST_ADD_OR_UDPATE_EVENT = 'REQUEST_ADD_OR_UDPATE_EVENT'
export const RESPONSE_ADD_OR_UDPATE_EVENT = 'RESPONSE_ADD_OR_UDPATE_EVENT'

export const REQUEST_ALL_EVENTS = 'REQUEST_ALL_EVENTS'
export const RESPONSE_ALL_EVENTS = 'RESPONSE_ALL_EVENTS'

export const REQUEST_UPDATE_EVENT_ESTATUS = 'REQUEST_UPDATE_EVENT_ESTATUS'
export const RESPONSE_UPDATE_EVENT_ESTATUS = 'RESPONSE_UPDATE_EVENT_ESTATUS'

export const REQUEST_CLONE_EVENT = 'REQUEST_CLONE_EVENT'
export const RESPONSE_CLONE_EVENT = 'RESPONSE_CLONE_EVENT'

export const REQUEST_UPDATE_TIPO = 'REQUEST_UPDATE_TIPO'
export const RESPONSE_UPDATE_TIPO = 'RESPONSE_UPDATE_TIPO'

export const REQUEST_UPDATE_SUBTIPO = 'REQUEST_UPDATE_SUBTIPO'
export const RESPONSE_UPDATE_SUBTIPO = 'RESPONSE_UPDATE_SUBTIPO'


//REDUCER
export const CLEAR_UPDATE_ESTATUS_REDUCER = 'CLEAR_UPDATE_ESTATUS_REDUCER';
export const CLEAR_CLONE_ESTATUS_REDUCER = 'CLEAR_CLONE_ESTATUS_REDUCER';
export const CLEAR_USER_LOGED_REDUCER = 'CLEAR_USER_LOGED_REDUCER';
export const CLEAR_UPDATE_TIPO_REDUCER = 'CLEAR_UPDATE_TIPO_REDUCER';
export const CLEAR_UPDATE_SUBTIPO_REDUCER = 'CLEAR_UPDATE_SUBTIPO_REDUCER';