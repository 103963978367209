
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// the translations
// (tip move them in a JSON file and import them)
import translationEN from '../../React/Recursos/locale-en_US.json';
import translationES from '../../React/Recursos/locale-es_MX.json';


// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,   
        lng: "es",
        debug: false,
        react: {
            wait: true,
            withRef: false,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        },
    interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;