import { Fragment, useState } from "react";

const FaqContainer = ({t}) => 
{
    const [openQuestion, setOpenQuestion] = useState("q1");

    const funcCloseAnswer = (q) => 
    {
        if(openQuestion != q && openQuestion != ""){
            document.getElementById(openQuestion).className = 'collapse'
            setOpenQuestion(q);
        }
    }

    return (

        <div className="section-class">
            <center>
                <h5 className="c-one">{t("_PREGUNTASFRECUENTES")}</h5>
                <div className="row-mobile">
                    <div className="col-12">
                        <p className="parraphDesc">
                            {t("_PREGUNTASFRECUENTES_Desc")}
                        </p>
                    </div>
                </div>
                <div className="row-mobile">
                    <div className="col-12 faq-ver" data-toggle="collapse" data-target="#faq-questions">
                        <center>
                            <p>
                                    <a className="faq-q">
                                        <i className="fa-solid fa-eye"></i><br></br>
                                        {t("_VerPreguntas")}
                                    </a>
                            </p>
                        </center>
                        
                        <div id="faq-questions" className="collapse">
                            <div className="faq-a">
                                <p className="parraphDesc">
                                    <strong>{t("_Q1")}</strong><br></br>
                                    {t("_A1")}
                                    <br></br><br></br>
                                    <strong>{t("_Q2")}</strong><br></br>
                                    {t("_Q2")}
                                    <br></br><strong>{t("_W2")}</strong>.
                                    <br></br><br></br>
                                    <strong>{t("_Q3")}</strong><br></br>
                                    {t("_A3")}
                                    <br></br><br></br>
                                    <strong>{t("_Q4")}</strong><br></br>
                                    {t("_A4")}
                                    <br></br><br></br>
                                    <strong>{t("_Q5")}</strong><br></br>
                                    {t("_A5")}<br></br><br></br>
                                    {t("_A5_1")}<br></br>
                                    {t("_A5_2")}<br></br>
                                    {t("_A5_3")}<br></br>
                                    {t("_A5_4")}<br></br>
                                    {t("_A5_5")}<br></br>
                                    {t("_A5_6")}<br></br>
                                    {t("_A5_7")}<br></br>
                                    {t("_A5_8")}<br></br>
                                    <br></br>
                                    <strong>{t("_Q6")}</strong><br></br>
                                    {t("_A6")}
                                    <br></br><br></br>
                                    <strong>{t("_Q7")}</strong><br></br>
                                    {t("_A7")}
                                    <br></br><br></br>
                                    <strong>{t("_Q8")}</strong><br></br>
                                    {t("_A8")}
                                    <br></br><br></br>
                                    <strong>{t("_Q9")}</strong><br></br>
                                    {t("_A9")}
                                    <br></br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </center>
        </div>
    );
}

export default FaqContainer;