import {
    RESPONSE_ADD_OR_UDPATE_EVENT,
    RESPONSE_ALL_EVENTS,
    RESPONSE_CLONE_EVENT,
    RESPONSE_FORM_CATALOG,
    RESPONSE_HOME_MODULE_INFO,
    RESPONSE_LOGIN_USER,
    RESPONSE_UPDATE_EVENT_ESTATUS,
    RESPONSE_UPDATE_SUBTIPO,
    RESPONSE_UPDATE_TIPO,
} from '../../Constants'


export default function home(state = {}, action) {
    switch (action.type) {
        case RESPONSE_HOME_MODULE_INFO:
            {
                const responseHomeModule = action.payload.response;
                if (responseHomeModule.code === 100) {
                    const homeModuleEs = responseHomeModule
                    return { ...state, errorLoadHomeModule: false, homeModuleEs }
                }
                else {
                    return { ...state, errorLoadHomeModule: true }
                }
            }
        case RESPONSE_ALL_EVENTS:
            {
                const responseFromService = action.payload.response;
                if (responseFromService.code === 200) {
                    const allEventsData = responseFromService
                    return { ...state, errorGettingAllEvents: false, allEventsData }
                }
                else {
                    return { ...state, errorGettingAllEvents: true }
                }
            }
        case RESPONSE_FORM_CATALOG:
            {
                const responseFormCatalog = action.payload.response;
                if (responseFormCatalog.code === 100) {
                    const adminModuleEs = responseFormCatalog
                    return { ...state, errorLoadHomeModule: false, adminModuleEs }
                }
                else {
                    return { ...state, errorLoadHomeModule: true }
                }
            }
        case RESPONSE_ADD_OR_UDPATE_EVENT:
            {
                const responseFromService = action.payload.response;
                if (responseFromService.code === 200) {
                    const updatedEventData = responseFromService
                    return { ...state, errorUpdatingEventsData: false, updatedEventData }
                }
                else {
                    return { ...state, errorUpdatingEventsData: true }
                }
            }
        case RESPONSE_UPDATE_EVENT_ESTATUS:
            {
                const responseFromService = action.payload.response;
                if (responseFromService.code === 200) {
                    const updatedEventStatus = responseFromService
                    return { ...state, errorUpdatingEventsStatus: false, updatedEventStatus }
                }
                else {
                    return { ...state, errorUpdatingEventsStatus: true }
                }
            }
        case RESPONSE_CLONE_EVENT:
            {
                const responseFromService = action.payload.response;
                if (responseFromService.code === 200) {
                    const cloneEventStatus = responseFromService
                    return { ...state, errorCloningEvent: false, cloneEventStatus }
                }
                else {
                    return { ...state, errorCloningEvent: true }
                }
            }
        case RESPONSE_LOGIN_USER:
            {
                const responseFromService = action.payload.response;
                if (responseFromService.code === 200) {
                    const userLoginResponse = responseFromService
                    if(userLoginResponse.serviceResponse.status == 200){
                        var fecha = new Date();
                        fecha.setMinutes(fecha.getMinutes() + 10)
                        return { ...state, errorUserLogin: false, userLoginResponse, userLoginExpiration: fecha.toLocaleString() }
                    }else{
                        return { ...state, errorUserLogin: true }
                    }
                }
                else {
                    return { ...state, errorUserLogin: true }
                }
            }
        case RESPONSE_UPDATE_TIPO:
            {
                const responseFromService = action.payload.response;
                if (responseFromService.code === 200) {
                    const updateTipoStatus = responseFromService
                    return { ...state, errorUpdatingTipo: false, updateTipoStatus }
                }
                else {
                    return { ...state, errorUpdatingTipo: true }
                }
            }
        case RESPONSE_UPDATE_SUBTIPO:
            {
                const responseFromService = action.payload.response;
                if (responseFromService.code === 200) {
                    const updateSubTipoStatus = responseFromService
                    return { ...state, errorUpdatingSubTipo: false, updateSubTipoStatus }
                }
                else {
                    return { ...state, errorUpdatingSubTipo: true }
                }
            }
        default:
            return { ...state }
    }
}