import { useEffect, useState } from "react";

const Slide1Container = ({t}) => {
    
    const [activityFlex, setActivityFlex] = useState(1);
    const [reloj, setReloj] = useState(1);
    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const runReloj = () =>{
        setTimeout(() => {
            if(document.getElementById("m-flex-1")){
                document.getElementById("m-flex-1").className = "item item-1"
                document.getElementById("m-flex-2").className = "item item-2"
                document.getElementById("m-flex-3").className = "item item-3"
                document.getElementById("m-flex-4").className = "item item-4"
                if(activityFlex > 3) setActivityFlex(1); else setActivityFlex(activityFlex + 1);
                document.getElementById("m-flex-" + activityFlex).className = "item item-" + activityFlex + " item-active"
            }
          }, "1000");
    }

    useEffect(() => {
        runReloj();
    },[activityFlex])

    return (
        <div className="carousel-texto-2">
            <br></br>
            <h2 className='poppins' style={{ fontSize: 25 }}>{t("_NuestrasActividades")}</h2>
            <center>
                <div className="row slide-mobile-version">
                    <div className="col-6 px-0">
                        <div id="m-flex-1" className="item item-1 item-active">
                            <h2 className="activity-tittle">{t("_ClasesCursosTalleres")}</h2>
                        </div>
                    </div>
                    <div className="col-6 px-0">
                        <div id="m-flex-2" className="item item-2">
                            <h2 className="activity-tittle">{t("_ViajesExcursiones")}</h2>
                        </div>
                    </div>
                </div>
                <div className="row slide-mobile-version">
                    <div className="col-6 px-0">
                        <div id="m-flex-3" className="item item-3">
                            <h2 className="activity-tittle">{t("_BarraBebidas")}</h2>
                        </div>
                    </div>
                    <div className="col-6 px-0">
                        <div id="m-flex-4" className="item item-4">
                            <h2 className="activity-tittle">{t("_ConvivenciasEventosEpeciales")}</h2>
                        </div>
                    </div>
                </div>
            </center>
            <div className="row slide-pc-version">
                <div className="col-12">
                    <center>
                    <a href="#actividades">
                        <div className="gallery-wrap">
                            <div className="item item-1">
                                <h2 className="activity-tittle">{t("_ClasesCursosTalleres")}</h2>
                            </div>
                            <div className="item item-2">
                                <h2 className="activity-tittle">{t("_ViajesExcursiones")}</h2>
                            </div>
                            <div className="item item-4">
                                <h2 className="activity-tittle">{t("_ConvivenciasEventosEpeciales")}</h2>
                            </div>
                        </div>
                    </a>
                    </center>
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col-sm-12 p-1">
                    <a href="#actividades" className="links-chaknuul">{t("_ConoceProximosEventos")}</a>
                </div>
            </div>
        </div>
    );
}
export default Slide1Container;