import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { requestClearSubTipoReducer, requestClearTipoReducer, requestFormCatalogs, requestUpdateSubTipo, requestUpdateTipo } from "../../../ReduxSaga/Actions/Home";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const TiposContainer = ({catalogosList}) => {

    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal)
    const appReducers = {};
    appReducers.home = useSelector((state) => state.home);

    const [tiposList, setTiposList] = useState([])
    const [subTiposList, setSubTiposList] = useState([])
    const [tipoSelect, setTipoSelect] = useState(0)
    const [idSubTipoSelect, setIdSubTipoSelect] = useState(0)
    const [subTipoSelect, setSubTipoSelect] = useState({})
    const [imgSelected, setImgSelected] = useState(false)
    const [loadFile, setLoadFile] = useState("")
    const [newTipo, setNewTipo] = useState("")
    const [showSubTipoForm, setShowSubTipoForm] = useState(false)
    const [imgSubTipo, setImgSubTipo] = useState()
    const [imgSend, setImgSend] = useState("");
    const [msgValidation, setMsgValidation] = useState("");

    //EFFECTS////EFFECTS////EFFECTS////EFFECTS////EFFECTS////EFFECTS////EFFECTS////EFFECTS////EFFECTS////EFFECTS////EFFECTS////EFFECTS////EFFECTS////EFFECTS//

    useEffect(() => {
        if(appReducers.home.adminModuleEs?.catalogos?.tipos){
            setTiposList(appReducers.home.adminModuleEs?.catalogos.tipos)
            if(tipoSelect != 0)
            {
                var subList = appReducers.home.adminModuleEs?.catalogos.tipos.filter(f => f.id == tipoSelect)[0].subTipos;
                setSubTiposList(subList)
                setShowSubTipoForm(false)
            }
        }
    },[appReducers.home.adminModuleEs?.catalogos?.tipos])

    useEffect(() => {
        if(tipoSelect != 0){
            var subList = tiposList.filter(f => f.id == tipoSelect)[0].subTipos;
            setSubTiposList(subList)
            setShowSubTipoForm(false)
        }
    },[tipoSelect])

    useEffect(() => {
        if(idSubTipoSelect != 0){
            var subTipo = subTiposList.filter(f => f.id == idSubTipoSelect)[0];
            setShowSubTipoForm(true)
            setSubTipoSelect(subTipo)
            setImgSelected(false)
            setImgSubTipo(subTipo.descripcion)
        }
    },[idSubTipoSelect])

    useEffect(() => {
        if(appReducers.home.updateTipoStatus?.code === 200)
        {
            dispatch(requestClearTipoReducer())
            dispatch(requestFormCatalogs())
            setNewTipo("")
            let timerInterval;
            Swal.fire({
                title: "Se guardo correctamente!",
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: () => {},
                willClose: () => {
                    clearInterval(timerInterval);
                }
                }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                }
            });
        }
    },[appReducers.home.updateTipoStatus])

    useEffect(() => {
        if(appReducers.home.updateSubTipoStatus?.code === 200)
        {
            dispatch(requestClearSubTipoReducer())
            dispatch(requestFormCatalogs())
            let timerInterval;
            Swal.fire({
                title: subTipoSelect.id == 0 ? "Se guardo correctamente!" : "Se actualizo correctamente!",
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: () => {},
                willClose: () => {
                    clearInterval(timerInterval);
                }
                }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                }
            });
        }
    },[appReducers.home.updateSubTipoStatus])

    //FUNCIONES////FUNCIONES////FUNCIONES////FUNCIONES////FUNCIONES////FUNCIONES////FUNCIONES////FUNCIONES////FUNCIONES////FUNCIONES////FUNCIONES////FUNCIONES//

    const handleLaunchAlert = () => {
    }

    const handleCargaImagen = (e) => {
        setImgSelected(true)
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setLoadFile(e.target.result);
            setImgSend(e.target.result)
        }
    }

    const handleSaveNewTipo = () => {
        var desc =  document.getElementById("descNewTipo").value;
        var newTipo = {
            id: 0,
            descripcion: desc   
        }
        dispatch(requestUpdateTipo(newTipo))
    }
    
    const handleNewSubTipo = () => {
        setSubTipoSelect({
            id: 0,
            descripcion: "",
            tipo: tipoSelect
        })
        setShowSubTipoForm(true)
        setImgSubTipo("imagen")
        setImgSelected("")
    }
    const handleSaveNewSubTipo = () => {
        setMsgValidation("")
        if(subTipoSelect.descripcion == "")
        {
            setMsgValidation("Nombre de subtipo no puede ir vacio")
            return
        }
        var imgloader = document.getElementById("imgLoad").src

        if(imgloader.includes("imagen"))
        {
            setMsgValidation("Seleccione una imagen")
            return
        }
        var imgMandar = imgSend.split(",")[1]
        if(!imgloader.includes("imagen") && !imgloader.includes("base64"))
        {
            imgMandar = "";
        }

        var newSubTipo = {
            id: subTipoSelect.id,
            descripcion: subTipoSelect.descripcion,
            idTipo: tipoSelect,
            file: imgMandar
        }
        dispatch(requestUpdateSubTipo(newSubTipo))
    }

    

    return <div>
        <div className="row">
            <div className="col-4">
                <table className="table-striped tablaEventos">
                    <thead>
                        <tr>
                            <th scope="col" width="10%"></th>
                            <th scope="col" width="10%">Id</th>
                            <th scope="col" width="70%">Tipo</th>
                            <th scope="col" width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        tiposList.map((e, index) => {
                            return (
                                <tr key={index}>
                                    <td></td>
                                    <td>{e.id}</td>
                                    <td>{e.descripcion}</td>
                                    <td><button onClick={() => setTipoSelect(e.id)}><i className="fa-solid fa-eye"></i></button></td>
                                </tr>
                            
                        )})
                    }
                    </tbody>
                </table>
                <div className="row m-1">
                    <div className="col-10">
                        <input type="text" value={newTipo} onChange={(e) => setNewTipo(e.target.value.replace(" ", ""))} id="descNewTipo" className="form-control"></input>
                    </div>
                    <div className="col-2 text-right">
                        <button disabled={newTipo == ""} onClick={() => handleSaveNewTipo()} className="form-control btn-primary">
                            <i className="fa-solid fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <table className="table-striped tablaEventos">
                    <thead>
                        <tr>
                            <th scope="col" width="10%"></th>
                            <th scope="col" width="10%">Id</th>
                            <th scope="col" width="70%">Sub Tipo</th>
                            <th scope="col" width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        subTiposList.map((e, index) => {
                            return (
                                <tr key={index}>
                                    <td></td>
                                    <td>{e.id}</td>
                                    <td>{e.descripcion}</td>
                                    <td><button onClick={() => setIdSubTipoSelect(e.id)}><i className="fa-solid fa-eye"></i></button></td>
                                </tr>
                            
                        )})
                    }
                    </tbody>
                </table>
                {
                    tipoSelect != 0 &&
                    <div className="row m-1">
                        <div className="col-10">
                        </div>
                        <div className="col-2 text-right">
                            <button onClick={() => handleNewSubTipo()} className="form-control btn-primary">
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                }
            </div>
            <div className="col-4">
                {
                    showSubTipoForm ?
                    <div>
                        <div className="row">
                            <div className="col-12">
                                Descripcion
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="text" onChange={(e) => setSubTipoSelect({ ...subTipoSelect, descripcion: e.target.value.replace(" ", "") })} value={subTipoSelect.descripcion} className="form-control"></input>
                            </div>
                            <div className="col-12">
                                <br></br>
                                Vista previa
                                <div className="card-event" data-toggle="modal">
                                    {
                                        imgSelected == "" ?
                                        <img id="imgLoad" className="img-event" src={imgSubTipo != "" ? `./img/proximos/${imgSubTipo}.png` : ' '} ></img>
                                        :
                                        <img id="imgLoad" className="img-event" src={loadFile} ></img>
                                    }
                                    <br></br>
                                    <input type="file" id="imgLoader" accept=".png" onChange={(e) => handleCargaImagen(e)} className="filetype m-1" />
                                </div>
                            </div>
                        </div>
                        <br></br>
                        {
                            msgValidation != "" ?
                                <div id="div-validation" className="alert alert-danger" role="alert">
                                    {msgValidation}
                                </div>
                                :
                                <Fragment></Fragment>

                        }
                        <br></br>
                        <div className="row">
                            <div className="col-9"></div>
                            <div className="col-3">
                                        <button onClick={() => handleSaveNewSubTipo()} className="form-control btn-primary">
                                            <center>
                                                {
                                                    subTipoSelect.id == 0 ? "Guardar" : "Actualizar"
                                                }
                                            </center>
                                        </button>
                            </div>
                        </div>
                    </div>
                    :
                    ""
                }
            </div>
        </div>
    </div>
}


export default TiposContainer;