import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestHomeModuleInfo,
} from "../../ReduxSaga/Actions/Home";
import Navbar from "../Navigation/Navbar";
import NosotrosContainer from "./HomeComponents/NosotrosContainer";
import EventsContainer from "./HomeComponents/EventsContainer";
import PlaceContainer from "./HomeComponents/PlaceContainer";
import InstructorsContainer from "./HomeComponents/InstructorsContainer";
import TeamContainer from "./HomeComponents/TeamContainer";
import FaqContainer from "./HomeComponents/FaqContainer";
import ContactContainer from "./HomeComponents/ContactContainer";
import { useTranslation } from "react-i18next";

const HomeContainer = () => {
  /// TRANSLATION
  const { t, i18n } = useTranslation();
  
  const dispatch = useDispatch();

  const appReducers = {};
  appReducers.user = useSelector((state) => state.user);
  appReducers.home = useSelector((state) => state.home);

  const [eventosList, setEventosList] = useState([])
  const [nextEvent, setNextEvent] = useState({})

  useEffect(() => {
    dispatch(requestHomeModuleInfo());
  },[])

  useEffect(() => {
    var nevent = eventosList.sort((a, b) => b.fechas - a.fechas)[0]
    setNextEvent(nevent)
  },[eventosList])

  useEffect(() => {
    if(appReducers.home.homeModuleEs?.eventos)
    {
      setEventosList(appReducers.home.homeModuleEs?.eventos.filter(f => f.activo));
    }
  },[appReducers.home.homeModuleEs?.eventos])



  const handleOpenWhatsApp = (msg)=>{
    if(msg)
    {
      window.open('https://api.whatsapp.com/send?phone=5611035720&text=Hola, quisiera mas informacion del evento ' + msg, '_blank')
    }
    else
    {
      window.open('https://api.whatsapp.com/send?phone=5611035720&text=Hola, quisiera mas informacion sobre sus actividades', '_blank')
    }
  }

  return (
    <Fragment>
      <Navbar 
        t={t}
        i18n={i18n}
        nextEvent={nextEvent}
      ></Navbar>
      <div id="actividades">
        <br></br>
        <br></br>
        <br></br>
      </div>
      <EventsContainer 
        t={t}
        handleOpenWhatsApp={handleOpenWhatsApp}
        eventosService={eventosList}
      ></EventsContainer>
      <div id="faq">
        <br></br>
        <br></br>
        <br></br>
      </div>
      <FaqContainer
        t={t}
      ></FaqContainer>
      <div id="contact">
        <br></br>
        <br></br>
        <br></br>
      </div>
      <ContactContainer 
        whatsapp={handleOpenWhatsApp}
        t={t}
      ></ContactContainer>
      <a onClick={() => handleOpenWhatsApp()} className="float" target="_blank">
        <i className="fa fa-whatsapp my-float fa-xg"></i>
      </a>
    </Fragment>
  );
};

export default HomeContainer;