import { useEffect, useState } from "react";
import EventContainer from "./EventContainer";
import { Link } from "react-router-dom";



const EventsContainer = ({handleOpenWhatsApp, eventosService, t}) => 
{
    const [msjEventos, setMsjEventos] = useState(t("_TodosEventos"));
    const [eventos, setEventos] = useState([]);
    const [eventosF, setEventosF] = useState(eventos);

    const activarCategoria = (cat) => 
    {
        document.getElementById("cat2").className = "";
        document.getElementById("cat3").className = "";
        document.getElementById("cat4").className = "";

        switch(cat){
            case "cat1":
                setMsjEventos(t("_TodosEventos"));
                setEventosF(eventos);
                break;
            case "cat2":
                setMsjEventos(t("_ProximasClasesCursosTalleres"));
                setEventosF(eventos.filter((f) => f.tipo=="clase").slice(0,4));
                document.getElementById(cat).className = "active-event";
                break;
            case "cat3":
                setMsjEventos(t("_ProximosViajesExcursiones"));
                setEventosF(eventos.filter((f) => f.tipo=="viaje").slice(0,4));
                document.getElementById(cat).className = "active-event";
                break;
            case "cat4":
                setMsjEventos(t("_ProximasConvivencias"));
                setEventosF(eventos.filter((f) => f.tipo=="especial").slice(0,4));
                document.getElementById(cat).className = "active-event";
                break;
        }
    }

    useEffect(() => {
        setEventos(eventosService);
    });

    useEffect(() => {
        setEventosF(eventosService.slice(0,4))
    }, [eventos]);

    return (
        <div className="section-class">
            <center>
                <h5 className="c-one">{t("_NUESTRASACTIVIDADES")}</h5>
                <div className="row-mobile">
                    <div className="col-12">
                        <p className="parraphDesc">{t("_QueBrindamos")}</p>
                    </div>
                </div>
                <div className="row-activities mb-2 mt-2">
                    <div className="col-4">
                        <div id="cat2" onClick={() => { activarCategoria("cat2") }} className="">
                            <img src="./img/activities/classes.png" className="imgRedonda p-1"></img>
                        </div>
                    </div>
                    <div className="col-4">
                        <div id="cat3" onClick={() => { activarCategoria("cat3") }} className="">
                            <img src="./img/activities/travels.png" className="imgRedonda p-1"></img>
                        </div>
                    </div>
                    <div className="col-4">
                        <div id="cat4" onClick={() => { activarCategoria("cat4") }} className="">
                            <img src="./img/activities/events.png" className="imgRedonda p-1"></img>
                        </div>
                    </div>
                </div>
                <div className="row-mobile">
                    <div className="col-12">
                        {msjEventos}
                        <br></br>
                        <a style={{ fontSize: 14, color: "blue" }} onClick={() => { activarCategoria("cat1") }}>{t("_VerTodos")}</a>
                        <br></br>
                        <div className="row">
                        {
                            eventosF.map((evento, index) => (
                                <div id={"divCo"  +index + "-" + evento.id} key={"divCo"  +index + "-" + evento.id} className="col-6 px-2">
                                    <EventContainer handleOpenWhatsApp={handleOpenWhatsApp} key={"divCo"  +index + "-" + evento.id} evento={evento} t={t}></EventContainer>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
                <br></br>
            </center>
        </div>
    );
}

export default EventsContainer;