import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BaseContainer from "./React/Modules/Base/BaseContainer";
import HomeContainer from "./React/Modules/Home/HomeContainer";
import AllEventsContainer from "./React/Modules/Eventos/AllEventsContainer";
import CmContainer from "./React/Modules/CM/CmContainer";
import LoginContainer from "./React/Modules/CM/CmComponents/LoginContainer";
import i18n from "./Libraries/Translation/i18n";

export default function routerApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <BaseContainer>
                        <HomeContainer /> 
                    </BaseContainer>
                    } 
                />
                <Route exact path="/eventos" element={
                    <BaseContainer>
                        <AllEventsContainer />
                    </BaseContainer>
                    } 
                />
                <Route exact path="/admin" element={
                    <CmContainer></CmContainer>
                    } 
                />
                <Route exact path="/login" element={
                    <LoginContainer></LoginContainer>
                    } 
                />
            </Routes>
        </BrowserRouter>
    )
}