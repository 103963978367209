import { combineReducers } from 'redux';
import home from './Home'
import {
  CLEAR_CLONE_ESTATUS_REDUCER,
  CLEAR_UPDATE_ESTATUS_REDUCER,
  CLEAR_UPDATE_SUBTIPO_REDUCER,
  CLEAR_UPDATE_TIPO_REDUCER,
  CLEAR_USER_LOGED_REDUCER,
} from './../Constants'

const appReducers = combineReducers({
  home
});


export default (state, action) => {
  
    if(action.type === CLEAR_UPDATE_ESTATUS_REDUCER)
    {
      delete state.home.updatedEventStatus
      delete state.home.errorUpdatingEventsStatus
    }

    if(action.type === CLEAR_CLONE_ESTATUS_REDUCER)
    {
      delete state.home.cloneEventStatus
      delete state.home.errorCloningEvent
    }

    if(action.type === CLEAR_USER_LOGED_REDUCER)
    {
      delete state.home.userLoginResponse
      delete state.home.errorUserLogin
    }

    if(action.type === CLEAR_UPDATE_TIPO_REDUCER)
    {
      delete state.home.errorUpdatingTipo
      delete state.home.updateTipoStatus
    }

    if(action.type === CLEAR_UPDATE_SUBTIPO_REDUCER)
    {
      delete state.home.errorUpdatingSubTipo
      delete state.home.updateSubTipoStatus
    }

    return appReducers(state, action);
  };
 