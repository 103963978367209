import { Fragment, useEffect, useState } from "react";
import './../../../../../src/login.css'
import { MD5 } from "crypto-js";
import { requestLoginUser } from "../../../ReduxSaga/Actions/Home";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const LoginContainer = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appReducers = {};
    appReducers.home = useSelector((state) => state.home);

    const [msgValidation, setMsgValidation] = useState("")
    const [loginModel, setLoginModel] = useState({
        Nombre: "",
        Usuario: "",
        Contrasena: "",
        Password: ""
    })

    const handleUserLogin = () => {
        if(loginModel.Usuario == "")
        {
            setMsgValidation("Usuario no valido")
            return;
        }
        if(loginModel.Contrasena == "")
        {
            setMsgValidation("Contraseña no valida")
            return;
        }
        dispatch(requestLoginUser(loginModel))
    }

    useEffect(() => {
        setMsgValidation("");
    },[loginModel.Usuario, loginModel.Contrasena])

    useEffect(() => {
        setLoginModel({ ...loginModel, Password: MD5(loginModel.Contrasena).toString()});
    },[loginModel.Contrasena])

    useEffect(() => {
        if(appReducers.home.userLoginResponse?.serviceResponse){
            if(appReducers.home.userLoginResponse?.serviceResponse?.status == 200){
                console.log("login correcto, redirigiendo")
                navigate("/admin");
            }
        }
    },[appReducers.home.userLoginResponse?.serviceResponse])

    useEffect(() => {
        if(appReducers.home.errorUserLogin){
            setMsgValidation("Usuario y/o Contraseña incorrectos")
        }
    },[appReducers.home.errorUserLogin])
    

    return (
        <Fragment>
            <div id="contenedor">
                <div id="central">
                    <div id="login">
                        <div className="titulo">
                            Chaknuul
                        </div>
                        <div id="loginform">
                            <input
                                value={loginModel.Usuario} 
                                onChange={(e) => setLoginModel({ ...loginModel, Usuario: e.target.value })} 
                                type="text" 
                                name="usuario" 
                                placeholder="Usuario" 
                            />
                            <input
                                value={loginModel.Contrasena}
                                onChange={(e) => setLoginModel({ ...loginModel, Contrasena: e.target.value })} 
                                type="password" 
                                placeholder="Contraseña" 
                                name="password" 
                            />
                            <button onClick={() => handleUserLogin()} type="submit" title="Ingresar" name="Ingresar">Login</button>
                        </div>
                        <br></br>
                        {
                            msgValidation != "" ?
                                <div className="alert alert-danger" role="alert">
                                    {msgValidation}
                                </div>
                                :
                                <Fragment></Fragment>

                        }
                        <br></br>
                        <center>
                            <Link to={"/"} style={{ textDecoration: 0, color: 'white' }}>Volver al sitio</Link>
                        </center>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default LoginContainer;