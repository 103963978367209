import { Fragment, useEffect, useState } from "react";
import LateralBarContainer from "./CmComponents/LateralBarContainer";
import PrincipalContainer from "./CmComponents/PrincipalContainer";
import { useDispatch, useSelector } from "react-redux";
import { requestAllEvents, requestClearCloneEstatusReducer, requestClearUpdateEstatusReducer, requestCloneEvent, requestFormCatalogs } from "../../ReduxSaga/Actions/Home";
import NewContainer from "./CmComponents/NewContainer";
import ListaEventosContainer from "./CmComponents/ListaEventosContainer";
import EditContainer from "./CmComponents/EditContainer";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from "react-router-dom";
import TiposContainer from "./CmComponents/TiposContainer";



const CmContainer = () => 
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal)
    const appReducers = {};
    appReducers.home = useSelector((state) => state.home);
    const [eventosList, setEventosList] = useState([])
    const [catalogosList, setCatalogosList] = useState([])
    const [activeSection, setActiveSection] = useState("lista")
    const [activeEventId, setActiveEventId] = useState(0);
    const [lastSelected, setLastSelected] = useState(0);
    const [eventSelected, setEventSelected] = useState({});
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [clonedOk, setClonedOk] = useState(false);

    useEffect(() => {
        dispatch(requestAllEvents());
        dispatch(requestFormCatalogs());
    },[])

    useEffect(() => {
        if(appReducers.home.allEventsData?.eventos)
        {
            setEventosList(appReducers.home.allEventsData?.eventos);
        }
        },[appReducers.home.allEventsData?.eventos])
        
    useEffect(() => {
        if(appReducers.home.adminModuleEs?.catalogos?.tipos)
        {
            setCatalogosList(appReducers.home.adminModuleEs?.catalogos.tipos);
        }
        },[appReducers.home.adminModuleEs?.catalogos?.tipos])

    useEffect(() => {
        if(appReducers.home.updatedEventStatus)
        {
            dispatch(requestAllEvents());
            dispatch(requestClearCloneEstatusReducer())
        }
        },[appReducers.home.updatedEventStatus])

    useEffect(() => {
        if(appReducers.home.cloneEventStatus)
        {
            dispatch(requestAllEvents());
            dispatch(requestClearCloneEstatusReducer())
            setClonedOk(true);
        }
        },[appReducers.home.cloneEventStatus])

    const handleNewEvent = () =>
    {
        setActiveEventId(0);
        setActiveSection("nuevo")
    }
    const handleEditEvent = () =>
    {
        setActiveSection("editar")
    }
    const handleMainList = () =>
    {
        setActiveSection("lista")
    }
    const handleSavedEvent = () =>
    {
        dispatch(requestAllEvents());
        setActiveSection("lista")
        let timerInterval;
        Swal.fire({
            title: "Se guardo correctamente!",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {},
            willClose: () => {
                clearInterval(timerInterval);
            }
            }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    }
    const handleDuplicateEvent = (id, fecha) => {
        dispatch(requestCloneEvent(id, fecha))
    }

    useEffect(() => {
        if(appReducers.home.userLoginExpiration){
            var fecha = new Date().toLocaleString();
            if(appReducers.home.userLoginExpiration < fecha){
                navigate("/login");
            }
        }else{
            navigate("/login");
        }
    },[appReducers.home.userLoginExpiration])

    useEffect(() => {
        if(appReducers.home.errorUserLogin){
            navigate("/login");
        }
    },[appReducers.home.errorUserLogin])

    return (
        <div className="CM-Main">

            <div className="row">
                <div className="col-12">
                    <center>
                    <br></br>
                    <h5>Admin Chaknúul</h5>
                    <br></br>

                    </center>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2">
                    <LateralBarContainer
                        setActiveSection={setActiveSection}
                    ></LateralBarContainer>
                </div>
                <div className="col-lg-9">
                {
                    activeSection == "lista" &&
                    <ListaEventosContainer 
                        eventosList={eventosList}
                        handleNewEvent={handleNewEvent}
                        lastSelected={lastSelected} 
                        setLastSelected={setLastSelected}
                        handleEditEvent={handleEditEvent}
                        handleDuplicateEvent={handleDuplicateEvent}
                        showDuplicateModal={showDuplicateModal}
                        setShowDuplicateModal={setShowDuplicateModal}
                        eventSelected={eventSelected}
                        setEventSelected={setEventSelected}
                        clonedOk={clonedOk}
                        setClonedOk={setClonedOk}
                        Swal={Swal}
                    ></ListaEventosContainer>
                }
                {
                    activeSection == "nuevo" &&
                    <NewContainer
                        catalogosList={catalogosList}
                        handleMainList={handleMainList}
                        handleSavedEvent={handleSavedEvent}
                    ></NewContainer>
                }
                {
                    activeSection == "editar" &&
                    <EditContainer
                        catalogosList={catalogosList}
                        eventSelected={eventSelected}
                        handleMainList={handleMainList}
                        handleSavedEvent={handleSavedEvent}
                    ></EditContainer>
                }
                {
                    activeSection == "tipos" &&
                    <TiposContainer
                        catalogosList={catalogosList}
                    ></TiposContainer>
                }
                </div>
            </div>
        </div>
    )
}

export default CmContainer;