
const EventContainer = ({ evento, handleOpenWhatsApp, t }) =>
{
    const getNameModal = (evento) =>
    {
        return "Modal-"+evento.id+"-"+evento.subTipo;
    }
    const getClassStyle = (tipo) =>
    {
        switch (tipo)
        {
            case "clase":
                return "title-event class-clase";
            case "viaje":
                return "title-event class-viaje";
            case "especial":
                return "title-event class-evento";
        }
    }
    const getFechaFormateada = (fecha) =>
    {
        var date = new Date(fecha);
        var mes = date.toLocaleString('default', { month: 'long' })
        if(date.getFullYear() > 2099)
            return "PROXIMAMENTE";
        else
            return ("0" + date.getDate()).slice(-2) + " " + mes.charAt(0).toUpperCase() + mes.slice(1) + " " + date.getFullYear();
    }
    const getHoraFormateada = (fecha) =>
    {
        var date = new Date(fecha);
        if(date.getFullYear() > 2099)
            return "";
        else
            return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + " Hrs";
    }

    return (
        <div>
            <div className="card-event" data-toggle="modal" data-target={`#${getNameModal(evento)}`}>
                <img className="img-event" src={`./img/proximos/${evento.subTipo}.png`} ></img>
                <h2 className={getClassStyle(evento.tipo)}>{evento.titulo}</h2>
                <h2 className="desc-event">{getFechaFormateada(evento.fecha)}<br></br>{getHoraFormateada(evento.fecha)}</h2>
                <div className="row">
                    <div className="col-8 text-left">
                    </div>
                </div>
            </div>
            
            <div className="modal fade modal-bg" id={`${getNameModal(evento)}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">{evento.titulo}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-justify">
                        {evento.informacion}<br></br><br></br>
                        {evento.objetivo}<br></br><br></br>
                        {evento.incluye}<br></br><br></br>
                        {
                            evento.llamado != "" ? (<p>{evento.llamado}</p>):(<div></div>)
                        }
                        {
                            evento.consideraciones != "" ? (<p>{evento.consideraciones}</p>):(<div></div>)
                        }
                        <br></br>
                        <center>
                        {getFechaFormateada(evento.fecha)}<br></br>
                        </center>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">{t("_Cerrar")}</button>
                        <a href="" onClick={() => handleOpenWhatsApp("" + evento.titulo + " del " + getFechaFormateada(evento.fecha))}><button type="button" className="btn btn-success"><i className="fa-brands fa-whatsapp fa-lg"></i>&nbsp;&nbsp;{t("_MeInteresa")}</button></a>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}
export default EventContainer;