
const URLBASE = 'https://chaknuul.mx/servicios'
//const URLBASE = 'https://localhost:44347'

class HomeModule {
    static async GetHomeModule() {
      var response = 
      {
        code: 100
      };

      await fetch(URLBASE + "/eventos")
        .then((response) => response.json())  
          .then((res) => {
           response.eventos = res.eventList;
          }
        );
      return response;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
    static async GetAllEvents() {
      var response = 
      {
        code: 200
      };

      await fetch(URLBASE + "/eventos/allEvents")
        .then((response) => response.json())  
          .then((res) => {
            response.eventos = res.eventList;
          }
        );
      return response;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
    static async GetCatalogos() {
      var response = 
      {
        code: 100
      };

      await fetch(URLBASE + "/eventos/catalogos")
        .then((response) => response.json())  
          .then((res) => {
           response.catalogos = res.catalogos;
          }
        );
      return response;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
    static async AddOrUpdateEvents(event) {
      var response = 
      {
        code: 200
      };

      await fetch(URLBASE + "/eventos/UpdateEvent", {
        method: "POST",
        body: JSON.stringify(event),
        headers: {"Content-type": "application/json;charset=UTF-8"}
      })
        .then((response) => response.json())  
          .then((res) => {
           response.serviceResponse = res;
          }
        );
      return response;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
    static async ChangeStatusEvent(id, estatus) {
      var response = 
      {
        code: 200
      };

      await fetch(URLBASE + "/Eventos/UpdateEventStatus?Id=" + id + "&Active=" + estatus, {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"}
      })
        .then((response) => response.json())  
          .then((res) => {
           response.serviceResponse = res;
          }
        );
      return response;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
    static async CloneEvent(id, fecha) {
      var response = 
      {
        code: 200
      };

      await fetch(URLBASE + "/Eventos/DuplicarEvento?Id=" + id + "&Fecha=" + fecha + "", {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"}
      })
        .then((response) => response.json())  
          .then((res) => {
           response.serviceResponse = res;
          }
        );
      return response;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
    static async RequestLoginUser(userModel) {      
      var response = 
      {
        code: 200
      };
      var newUserModel = {
        Nombre: "",
        Usuario: userModel.Usuario,
        Password: userModel.Password
      }

      await fetch(URLBASE + "/Eventos/Login", {
        method: "POST",
        body: JSON.stringify(newUserModel),
        headers: {"Content-type": "application/json;charset=UTF-8"}
      })
        .then((response) => response.json())  
          .then((res) => {
           response.serviceResponse = res;
          }
        );
      return response;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
    static async RequestUpdateTipo(tipoModel) {      
      var response = 
      {
        code: 200
      };

      await fetch(URLBASE + "/Eventos/UpdateTipo", {
        method: "POST",
        body: JSON.stringify(tipoModel),
        headers: {"Content-type": "application/json;charset=UTF-8"}
      })
        .then((response) => response.json())  
          .then((res) => {
           response.serviceResponse = res;
          }
        );
      return response;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
    static async RequestUpdateSubTipo(subTipoModel) {      
      var response = 
      {
        code: 200
      };

      var jsonModel = JSON.stringify(subTipoModel);

      await fetch(URLBASE + "/Eventos/UpdateSubTipo", {
        method: "POST",
        body: jsonModel,
        headers: {"Content-type": "application/json;charset=UTF-8"}
      })
        .then((response) => response.json())  
          .then((res) => {
           response.serviceResponse = res;
          }
        );
      return response;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
  }
  
  export default HomeModule