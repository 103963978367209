import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { requestAddOrUpdateEvento } from "../../../ReduxSaga/Actions/Home";

const EditContainer = ({
    eventSelected,
    catalogosList,
    handleMainList,
    handleSavedEvent
}) => {
    const dispatch = useDispatch();
    const appReducers = {};
    appReducers.home = useSelector((state) => state.home);

    const [tipos, setTipos] = useState([])
    const [subTipos, setSubTipos] = useState([])
    const [msgValidation, setMsgValidation] = useState("")
    const [fechaEvento, setFechaEvento] = useState(new Date())
    const [sinFecha, setSinFecha] = useState(false);
    const [saving, setSaving] = useState(false);
    const [validEvent, setValidEvent] = useState(false);
    const [eventLoaded, setEventLoaded] = useState(false)
    const [evento, setEvento] = useState({
        id:0,
        fecha: fechaEvento.getFullYear() + "-" + ("0" + (fechaEvento.getMonth() + 1)).slice(-2) + "-" + ("0" + fechaEvento.getDate()).slice(-2) + "T" + fechaEvento.getHours() + ":" + fechaEvento.getMinutes(),
        tipo: 0,
        subTipo:0,
        titulo:"",
        informacion:"",
        objetivo:"",
        incluye:"",
        llamado:"",
        idioma:"ESP",
        consideraciones:"",
        activo:true
    });

    useEffect(() => {
        setEvento(eventSelected);
        if((new Date(eventSelected.fecha)).getFullYear() >= 2100)
        {
            setSinFecha(true);
            document.getElementById("cb-fecha").checked = true;
            setFechaEvento(new Date());
        }
        else
        {
            setFechaEvento(new Date(eventSelected.fecha));
        }
        setEvento({ 
            ...eventSelected, 
            tipo: parseInt(eventSelected.idTipo), 
            subTipo: parseInt(eventSelected.idSubTipo)
        });
        setEventLoaded(true);
        //setEvento({ ...eventSelected, subTipo: parseInt(eventSelected.idSubTipo)});
    },[eventSelected])

    useEffect(() => {
        if(catalogosList){
            setTipos(catalogosList);
        }
    },[catalogosList])

    useEffect(() =>{
        if(evento.tipo != 0){
            let stipo = tipos.filter(f => f.id == evento.tipo)[0]
            if(stipo != undefined){
                setSubTipos(stipo.subTipos);
            }
        }
        else
        {
            setSubTipos([])
        }
    },[evento.tipo])
    
    useEffect(() => {
        if(appReducers.home.updatedEventData)
        {
            if(appReducers.home.updatedEventData.code == 200 && saving){
                handleSavedEvent();
                setSaving(false);
            }
        }
        },[appReducers.home.updatedEventData])

    useEffect(() => {
        if(validEvent)
        {
            dispatch(requestAddOrUpdateEvento(evento)); 
            setSaving(true);
            setValidEvent(false)
        }
    },[validEvent])

    const setNoDate = () =>
    {
        var cb = document.getElementById("cb-fecha").checked
        if(cb)
        {
            setSinFecha(true);
        }
        else
        {
            setSinFecha(false);
        }
    }
    const getFechaFormateada = (fecha) =>
    {
        var date = new Date(fecha);
        return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + "T" + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) ;
    }
    const handleValidateEvent = () =>
    {
        if(!document.getElementById("cb-fecha").checked)
        {
            if(fechaEvento < (new Date()))
             {
                 setMsgValidation("Fecha invalida")
                 return;
             }
             setEvento({ ...evento, fecha:getFechaFormateada(fechaEvento) })
        }
        else
        {
            setEvento({ ...evento, fecha:"2100-01-01T00:00" })
        }
        if(evento.titulo == "")
        {
            setMsgValidation("Ingrese el titulo del evento")
            return;
        }
        if(evento.tipo == 0)
        {
            setMsgValidation("Ingrese la categoria del evento")
            return;
        }
        if(evento.subTipo == 0)
        {
            setMsgValidation("Seleccione la sub categoria del evento")
            return;
        }
        setEvento({ ...evento, subTipo:evento.subTipo+"", tipo:evento.tipo+"" })
        if(evento.informacion == "")
        {
            setMsgValidation("Ingrese la informacion del evento")
            return;
        }
        if(evento.objetivo == "")
        {
            setMsgValidation("Ingrese el objetivo del evento")
            return;
        }
        setValidEvent(true);
    }

    useEffect(() => {
        if(eventLoaded){
            if(!document.getElementById("cb-fecha").checked)
            {
                setEvento({ ...evento, fecha:getFechaFormateada(fechaEvento) })
            }
            else
            {
                setEvento({ ...evento, fecha:"2100-01-01T00:00" })
            }
        }
    },[fechaEvento])

    return (
        <div>
            <div className="row">
                <div className="col-12"><h5>Editar Evento</h5></div>
            </div>
            <div className="row">
                <div className="col-3">Titulo</div>
                <div className="col-2">Categoria</div>
                <div className="col-2">Sub Categoria</div>
                <div className="col-1">Sin Fecha</div>
                <div className="col-2">Fecha</div>
                <div className="col-2">Hora</div>
            </div>
            <div className="row">
                <div className="col-3">
                    <input 
                        id="titulo" 
                        className="form-control"
                        type="text"
                        value={evento.titulo}
                        onChange={(e)=>{setEvento({ ...evento, titulo: e.target.value })}}
                    ></input>
                </div>
                <div className="col-2">
                    <select className="form-control" value={evento.tipo} onChange={(e)=>{setEvento({ ...evento, tipo: e.target.value })}}>
                        <option value={0} >Selecciona</option>
                        {
                            tipos.map((t, index) => (
                                <option key={index} value={t.id}>{t.descripcion.charAt(0).toUpperCase() + t.descripcion.slice(1)}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-2">
                    <select className="form-control" value={evento.subTipo} onChange={(e)=>{setEvento({ ...evento, subTipo: e.target.value })}}>
                        <option value={0}>Selecciona</option>
                        {
                            subTipos.map((t, index) => (
                                <option key={index} value={t.id}>{t.descripcion.charAt(0).toUpperCase() + t.descripcion.slice(1)}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-1">
                    <center>
                        <input id="cb-fecha" onClick={() => {setNoDate()}} type="checkbox"></input>
                    </center>
                </div>
                <div className="col-2">

                <DatePicker
                    readOnly={sinFecha}
                    id="dp-fecha"
                    className="form-control"
                    selected={fechaEvento}
                    onChange={(date) => {setFechaEvento(date)}}
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                />
                </div>
                <div className="col-2">
                    <DatePicker
                        readOnly={sinFecha}
                        id="dp-hora"
                        className="form-control"
                        selected={fechaEvento}
                        onChange={(date) => {setFechaEvento(date)}}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="hh:mm aa"
                        minDate={new Date()}
                    />
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col-6">
                    Informacion
                </div>
                <div className="col-6">
                    Objetivo
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <textarea
                        rows="5"
                        className="form-control"
                        value={evento.informacion}
                        onChange={(e)=>setEvento({ ...evento, informacion: e.target.value })}
                    ></textarea>
                </div>
                <div className="col-6">
                    <textarea
                        rows="5"
                        value={evento.objetivo}
                        className="form-control"
                        onChange={(e)=>setEvento({ ...evento, objetivo: e.target.value })}
                    ></textarea>
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col-6">
                    Incluye
                </div>
                <div className="col-6">
                    Llamado
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <textarea
                        rows="5"
                        className="form-control"
                        value={evento.incluye}
                        onChange={(e)=>setEvento({ ...evento, incluye: e.target.value })}
                    ></textarea>
                </div>
                <div className="col-6">
                    <textarea
                        rows="5"
                        value={evento.llamado}
                        className="form-control"
                        onChange={(e)=>setEvento({ ...evento, llamado: e.target.value })}
                    ></textarea>
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col-6">
                    Consideraciones
                </div>
                <div className="col-6">
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <textarea
                        rows="5"
                        className="form-control"
                        value={evento.consideraciones}
                        onChange={(e)=>setEvento({ ...evento, consideraciones: e.target.value })}
                    ></textarea>
                </div>
            </div>
            <br></br>
            {
                msgValidation != "" ?
                    <div id="div-validation" className="alert alert-danger" role="alert">
                        {msgValidation}
                    </div>
                    :
                    <Fragment></Fragment>

            }
            <div className="row">
                <div className="col-9">
                </div>
                <div className="col-3 text-right">
                    <button className="btn btn-outline-danger mybtn" onClick={()=>handleMainList()}>
                        <i className="fa-solid fa-xmark"></i>&nbsp;
                        Cancelar
                    </button>
                    &nbsp;
                    &nbsp;
                    <button className="btn btn-outline-success mybtn" onClick={()=>handleValidateEvent()}>
                        <i className="fa-regular fa-floppy-disk"></i>&nbsp;
                        Actualizar Evento
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EditContainer;