import { Fragment } from "react"

const LateralBarContainer = ({setActiveSection}) => 
{

    return (
        <div>
            <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                    <button onClick={() => { setActiveSection("lista") }} className="form-control">Eventos</button>
                    <br></br>
                </div>
                <div className="col-1"></div>
            </div>
            <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                    <button onClick={() => { setActiveSection("tipos") }} className="form-control">Tipos y SubTipos</button>
                    <br></br>
                </div>
                <div className="col-1"></div>
            </div>
        </div>
    );
}

export default LateralBarContainer;